import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = ({ data: { allContentfulHomePage} }) => {
  let { header, sections } = allContentfulHomePage.nodes[0];
  return (
    <Layout>
      <SEO title="Home Page" />
      <h1>{header}</h1>
      <div>
      {sections.map(section => <h2>{section.title}</h2>)}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allContentfulHomePage {
      nodes {
        header
        sections {
          ... on ContentfulPhotoGallerySection {
            title
          }
          ... on ContentfulSliderSection {
            title
          }
        }
      }
    }
  }
`

export default IndexPage
